import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LOGOSVG } from '../../assets';
import loginImg from '../../assets/images/auth_root_bg_img.png';
import AuthAxios from '../../axios';
import OpenAppBtn from '../../components/common/OpenAppBtn';
import KakaoLoginBtn from '../../components/socialLogin/KakaoLoginBtn';
import { AuthStateContext } from '../../contexts/AuthContext';
import { ThirdPartyContext } from '../../contexts/ThirdPartyContext';
import { ModalContext } from '../../contexts/ModalContext';
import { Login } from '../../types';
import { getItem, LocalLanguage, setItem, USERINFO, USERTOKEN } from '../../utils/localStorage';
import ModalWrapper from '../../components/modals/ModalWrapper';
import FallBackImg from '../../assets/images/fallback.jpg';

export default function DBSigninPage(props: any) {
  const { t } = useTranslation();
  const lan = getItem(LocalLanguage);
  const location = useLocation();
  const navigate = useNavigate();
  const { setLoginSuccess } = useContext(AuthStateContext);
  const { state: thirdPartyState, setState: setThirdPartyState, createThirdPartyUser } = useContext(ThirdPartyContext);
  const { handleModal } = useContext(ModalContext);
  const [originalModal, setOriginalModal] = useState<boolean>(true);

  useEffect(() => {
    const { isVip } = thirdPartyState;
    if (originalModal) {
      handleModal(
        <ModalWrapper title="무제한 체험권 당첨!">
          <h1>로그인 시, 건강향상 사운드</h1>
          <h1>{isVip ? 30 : 14}일 무제한 체험이 적용됩니다.</h1>
          <h1 className="before:content-['•'] before:relative before:-left-2 text-N4">
            마케팅 동의 시, 총 {isVip ? 60 : 30}일 체험
          </h1>
        </ModalWrapper>
      );

      handleModal(
        <ModalWrapper title="무제한 체험권 당첨!">
          <h1>로그인 시, 건강향상 사운드</h1>
          <h1>{isVip ? 30 : 14}일 무제한 체험이 적용됩니다.</h1>
          <h1 className="before:content-['•'] before:relative before:-left-2 text-N4">
            마케팅 동의 시, 총 {isVip ? 60 : 30}일 체험
          </h1>
        </ModalWrapper>
      );
    }
  }, [location, navigate, setThirdPartyState]);

  const postSignIn = async ({
    type,
    socialId,
    email,
    ageRange,
    birthday,
    birthdayType,
    birthYear,
    gender,
    name,
    phoneNumber,
    forceSignUp,
  }: {
    type: string;
    socialId: string;
    email: string;
    ageRange?: string;
    birthday?: string;
    birthdayType?: 'LUNAR' | 'SOLAR';
    birthYear?: string;
    gender?: 'male' | 'female';
    name?: 'string';
    phoneNumber?: string;
    forceSignUp?: boolean;
  }) => {
    return AuthAxios.post<Login>('/auth/social', {
      type,
      socialId,
      email,
      ageRange,
      birthday,
      birthdayType,
      birthYear,
      gender,
      name,
      phoneNumber,
      forceSignUp,
    })
      .then((resolve) => {
        const { user, accessToken, refreshToken, subscriptionInfo } = resolve.data;

        //subscriptionInfo 나중에 로그인 후 구독권 만료에 대한 고지용 팝업 .
        setItem(USERINFO, user);
        setItem(USERTOKEN, { accessToken, refreshToken });
        setLoginSuccess(user);

        return user;
      })
      .then((user) => {
        console.log(user);
        if (user.termsAgreed) {
          if (user.gender && user.birthday) {
            return navigate('/app/home');
          } else {
            return navigate('/auth/basicInfo-db');
          }
        }
        return navigate('/auth/agrement');
      })
      .catch((error) => {
        navigate('/ai-survey/signin', { replace: true });
        if (
          error.response.status === 409 &&
          (error.response.data.message as string).indexOf('email already exists') !== -1
        ) {
          console.log('error!!!:', error);
          const message = error.response.data.message;
          const { email, socials } = error.response.data.options;
          setOriginalModal(false);
          handleModal(
            <div className="w-[300] bg-N10 flex flex-col items-center rounded-lg text-N0 p-2 px-4">
              <p>{lan === 'en' ? message : '이미 가입된 이메일입니다.'}</p>
              <p>
                {lan === 'en'
                  ? 'To create a new social account with the same email, please click the button below.'
                  : '동일한 이메일로 다른 소셜 계정을 등록하려면 아래 버튼을 누르세요.'}
              </p>
              <br />
              <p>Email: {email}</p>
              <p>Social: {socials}</p>
              <div className="w-full h-20 flex flex-row place-content-center gap-6 text-N0 p-2 px-4">
                <button
                  className="flex-1 shrink-0 bg-N5 text-N10 rounded-lg text-base font-semibold"
                  onClick={() => {
                    handleModal();
                    setOriginalModal(true);
                  }}
                >
                  {lan === 'en' ? 'Cancel' : '취소'}
                </button>
                <button
                  className="flex-1 shrink-0 bg-N5 text-N10 rounded-lg text-base font-semibold"
                  onClick={() => {
                    handleModal();
                    setOriginalModal(true);
                    postSignIn({
                      type,
                      socialId,
                      email,
                      ageRange,
                      birthday,
                      birthdayType,
                      birthYear,
                      gender,
                      name,
                      phoneNumber,
                      forceSignUp: true,
                    });
                  }}
                >
                  {lan === 'en' ? 'Process Anyway' : '계속 진행하기'}
                </button>
              </div>
            </div>
          );
          return;
        }

        let message = error.response.data.message;
        if (error.response.data.message === '재가입은 탈퇴 3개월 이후부터 가능합니다.' && lan === 'en') {
          message = 'You can re-sign up 3 months after deleting your account.';
        }
        handleModal(
          <div className="w-[300] bg-N10 flex flex-col items-center rounded-[22px] text-N0 p-2 px-4">
            <div>{message ?? ''}</div>
            <button
              className="w-full bg-N5 text-N10 rounded-[22px] text-base font-semibold"
              onClick={() => handleModal()}
            >
              {lan === 'en' ? 'ok' : '확인'}
            </button>
          </div>
        );
      });
  };

  const afterSocialLoginSuccess = ({
    type,
    socialId,
    email,
    ageRange,
    birthday,
    birthdayType,
    birthYear,
    gender,
    name,
    phoneNumber,
  }: {
    type: string;
    socialId: string;
    email: string;
    ageRange?: string;
    birthday?: string;
    birthdayType?: 'LUNAR' | 'SOLAR';
    birthYear?: string;
    gender?: 'male' | 'female';
    name?: 'string';
    phoneNumber?: string;
  }) => {
    return postSignIn({
      type,
      socialId,
      email,
      ageRange,
      birthday,
      birthdayType,
      birthYear,
      gender,
      name,
      phoneNumber,
    });
  };

  return (
    <div
      className="w-screen h-screen overflow-y-auto no-scrollbar"
      style={{
        backgroundImage: `url(${loginImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'left top',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="flex flex-col items-center justify-start w-full">
        <div className="flex items-center justify-between w-full px-5 mt-5 md:flex-row-reverse mb-[128px]">
          <LOGOSVG className="md:hidden w-[93px] " />
          <div className="flex gap-[6.75px]">
            <OpenAppBtn />
          </div>
        </div>

        <div className="w-full md:w-[30rem] flex flex-col items-center gap-12 text-lg font-bold text-N0 leading-[26px] mb-3 md:mb-[21px] md:text-xl">
          {/* font pretendard */}
          <LOGOSVG className="max-md:hidden mb-[34px] md:w-[163px]" />
          <h1 className="text-center">{t('web.introText')}</h1>

          <div className="w-full mt-12 flex flex-col items-center justify-between gap-0">
            <span className="text-N0 font-extrabold text-xl">{t('web.socialLogin')}</span>
            <div className="flex mt-6 justify-center w-full h-[48px] gap-1 md:gap-3">
              <KakaoLoginBtn afterSocialLoginSuccess={afterSocialLoginSuccess} dbinsurance={true} />
            </div>
          </div>
        </div>

        <div className="w-[300px] sm:w-[900px] mt-32 flex place-content-center">
          <div className="w-full min-h-[100px] mx-6 flex justify-center items-center">
            <Link
              className="w-[300px] h-[100px] block sm:hidden"
              to="https://direct.idblife.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/db_banner_300x100_v1.jpg"
                alt="dblife-banner"
                className="w-[300px] h-[100px]"
                onError={(event) => {
                  event.currentTarget.src = FallBackImg;
                }}
                loading="lazy"
              />
            </Link>
            <Link
              className="w-[728px] hidden sm:block"
              to="https://direct.idblife.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/db_banner_728x90_v1.jpg"
                alt="dblife-banner"
                className="w-full h-full"
                onError={(event) => {
                  event.currentTarget.src = FallBackImg;
                }}
                loading="lazy"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
