import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { LOGOSVG } from '../../assets';
import OpenAppBtn from '../../components/common/OpenAppBtn';
import SocialLoginButtons from '../../components/socialLogin/SocialLoginButtons';
import { ThirdPartyContext } from '../../contexts/ThirdPartyContext';

export default function LoginMainPage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { state: collaborationState, setState: collaborationSetState } = useContext(ThirdPartyContext);

  useEffect(() => {
    // 살루스케어(비타포트)에서 유입된 유저
    if (!searchParams.has('provNo')) {
      return;
    }

    collaborationSetState({
      ...collaborationState,
      thirdPartyId: searchParams.get('provNo') || '',
      thirdPartyCode: searchParams.get('provNo') === '11' ? 'VITAPORT' : undefined,
      thirdPartyUserId: searchParams.get('mberNo') || '',
      orderNumber: searchParams.get('orderNo') || '',
    });
  }, []);

  return (
    <div className="flex flex-col items-center justify-start w-full">
      <div className="flex items-center justify-between w-full px-5 mt-5 md:flex-row-reverse mb-[128px]">
        <LOGOSVG className="md:hidden w-[93px] " />
        <div className="flex gap-[6.75px]">
          <OpenAppBtn />
        </div>
      </div>
      <div className="flex flex-col items-center text-lg font-bold text-N0 leading-[26px] mb-3 md:mb-[21px] md:text-xl">
        <LOGOSVG className="max-md:hidden mb-[34px] md:w-[163px]" />
        <h1 className="text-center md:w-[30rem]">{t('web.introText')}</h1>
      </div>
      <div className=" md:w-[18rem] h-[82px]  flex flex-col items-center justify-between mb-[56px]">
        <span className="text-[15px] text-N1 font-medium">{t('web.socialLogin')}</span>
        <div className="flex justify-between w-full h-[48px] gap-1 md:gap-3">
          <SocialLoginButtons />
        </div>
      </div>
    </div>
  );
}
