import React, { useEffect, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthAxios from '../axios';

export default function RouteGuard() {
  const location = useLocation();
  const navigate = useNavigate();
  useLayoutEffect(() => {
    const prefixPath = location.pathname.split('/').filter(Boolean)[0] || '';
    const secondPath = location.pathname.split('/').filter(Boolean)[1] || '';
    if (
      prefixPath === 'app' &&
      secondPath !== 'terms-of-use' &&
      secondPath !== 'privacy-policy' &&
      secondPath !== 'marketing-information'
    ) {
      AuthAxios.get(`/users`)
        .then((response) => {
          if (!response.data) {
            navigate('/auth');
          }
        })
        .catch((error) => {
          navigate('/auth');
        });
    }
  }, [location, navigate]);
  return null;
}
